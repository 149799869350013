.frame {
    height: 100vh;

    .rs-sidebar {
        background-color: #fff;
        flex: 1 1 auto;
        transition: none !important;
        border-top: 1px solid @B100;
        width: 100%;
    }

    .rs-content {
        padding: 0 10px;

        .rs-panel-header {
            .title {
                font-size: 18px;
            }
            
            .rs-breadcrumb {
                margin-bottom: 0;
            }
        }
    }

    .nav-toggle {
        border-top: 1px solid @B100;
    }

    .rs-sidenav-item, .rs-dropdown-item {
        &.active {
            color: @H500 !important;
        }

        &-icon {
            height: 1.3em !important;
            width: 1.3em !important;
        }
    }

    .brand {
        padding: 10px 18px;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        text-transform: uppercase;
        
        a {
            text-decoration: none;
        }
    }
}