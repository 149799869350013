.error-page {
    display: flex;
    height: 100vh;
    margin-top: -40px;
    justify-content: center;
    align-items: center;
  
    &-code{
      color: @B700;
    }

    &-title {
      font-size: 1.5rem;
      font-weight: bold;
    }
    
    &-subtitle {
      margin: 10px 0 20px 0;
    }
  
    .item {
      img {
        height: 260px;
      }
      .text {
        text-align: center;
      }
    }
  }
  