@import '~rsuite/styles/index.less';

@import '../components/Frame/styles.less';
@import '../components/Header/styles.less';
@import '../components/ErrorPage/styles.less';

@import '../components/Dashboard/styles.less';

//rewrite base color
@base-color: #34c3ff;

body {
  background: #f5f8fa;
//   background-image: url('../asserts/bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.text-muted {
  color: @B700;
}

.rs-sidebar {
  position: fixed;
  height: 100vh;
  z-index: 3;
}

.page-container {
  padding-left: 260px;
  transition: padding 0.5s;

  &.container-full {
    padding-left: 60px;
  }
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}

.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}

.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.rs-panel-header{
  padding: 20px 10px;
}

.rs-panel-body {
  padding: 10px;
}

.grt-stack {
  margin: 5px;
}

.grt-breadcrumb {
  font-size: 16px;
}

.grt-panel {
  position: relative;
  background: #fff;
  margin-bottom: 8px;
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
}

.grt-table {
  position: relative;
  background-color: #fff;
  margin-bottom: 5px;
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);

  .rs-table-row-header {
    font-size: 14px;
  }
}

.grt-page {
  padding: 20px;
  background-color: #fff;
  // style={{ padding: 20, background: '#fff' }}
}